import React, { useEffect, useState } from "react";
import Footer from "../common/Footer";
import TechHeader from "./TechHeader";
import "../../scss/tech.scss";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import APIURI from "../modules/APIURI";

export default function TechList(prop) {
  let lang = prop.lang ? prop.lang : "ko";
  let params = useParams();
  let sid;
  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelectorAll(".header_wrap").forEach(function (element) {
      element.style.display = "none";
    });
    loadData();
  }, [params]);
  let [content, setContent] = useState("");
  let [errorcode, setErrorcode] = useState("");
  let [date, setDate] = useState("");
  let [title, setTitle] = useState("");
  let [category, setCategory] = useState("");

  let [list, setList] = useState([]);

  function loadData(id) {
    sid = id ? id : params.id;
    //let _url = APIURI+type.toLowerCase()+'/'+lang.toLowerCase()+'/1/'
    //let _url = "/resources/tech/" + lang + "/" + sid + ".json";
    let _url = APIURI + "tech/" + lang + "/1/?pid=" + sid;
    //let _url = "https://api.mopiclabs.com/board/tech/" + lang + "/1/?pid=" + sid;

    axios.get(_url, {}).then(function (response) {
      setTitle(response.data.post_title);
      setDate(response.data.regist_date.split("T")[0]);
      setContent(response.data.post_contents);
      setErrorcode(response.data.post_errorcode.join(", "));
      setCategory(response.data.post_category);
      loadRelList(response.data.post_category, response.data.post_id);
    });
    /*let html_url = "/resources/tech/" + lang + "/" + sid + ".html";

    axios.get(html_url, {}).then(function (response) {
      setContent(response.data);
    });*/
  }
  function loadRelList(_category, _id) {
    let _url = APIURI + "tech/" + lang + "/0";
    axios.get(_url, {}).then(function (response) {
      const res = response.data.result;
      let _list = [];
      let idAry = [];
      res.forEach((item) => {
        if (item.post_category.some((value) => _category.includes(value))) {
          if (
            item.post_id !== _id &&
            _list.length < 5 &&
            idAry.indexOf(item.post_id) === -1
          ) {
            idAry.push(item.post_id);
            _list.push(item);
          }
        }
      });
      setList(_list);
    });
  }
  return (
    <>
      <div id="container">
        <TechHeader lang={lang} />
        <div id="contents" className="tech_solution view_page">
          <div className="tech_inner">
            <p className="tech_title">Technical Solution</p>
            <div className="view_wrap">
              <div className="view_title">
                <p className="tit"> {title}</p>
                <p className="date">{date}</p>
              </div>
              <div
                className="view_cont"
                dangerouslySetInnerHTML={{ __html: content }}></div>
              {errorcode ? (
                <p className="errorcode">Error Code : {errorcode}</p>
              ) : null}
              {/*      
                            <div className="view_cont" >
                                

                           


                            <p className="ft_b mt50 mb50">For more detailed information, please refer to the MOPIC Mirror manual: </p>
                            <ol className='circle'>
                                <li><p><a href='https://download.mopiclabs.com/release/mopicmirror/manual/MOPIC Mirror_1.2.x_User Guide_EN.pdf' target='_blank'>[EN] Manual for MOPIC Mirror (Ver. 1.2.x ~)</a></p></li>
                                <li>
                                    <p><a href='https://download.mopiclabs.com/release/mopicmirror/manual/MOPIC Mirror_1.2.x_User Guide_KR.pdf' target='_blank'>[KR] Manual for MOPIC Mirror (Ver. 1.2.x ~)</a></p>
                                </li>
                            </ol>

                                <p>MOPIC Mirror requires an initial one-time online product license authentication.</p>
                                <p>Due to firewall settings in highly secure company networks, authentication may not be possible.</p>
                                <p className="ft_b mt50 mb50">First, try running MOPIC Mirror with administrator mode. If this fails, check and follow the steps below:</p>
                                <ol className='decimal'>
                                    <li><p>Open the Command Prompt by entering [Windows > Search > CMD].</p></li>
                                    <li><p>Enter the following commands in the Command Prompt.</p></li>
                                    <li><p>If the result in the prompt includes 'FAIL', please perform the initial one-time authentication in an environment with relaxed firewall settings.</p>
                                        <div className='highlight theme-dark mx-w800'>
                                            <div class='codeHeader'><span class='language'>PowerShell</span></div>
                                            <pre class='has-inner-focus'>
                                                <code class='lang-powershell'>
                                                    <span>
                                                        <span class='hljs-pscommand'>curl </span>
                                                        <span class='hljs-parameter'>-H </span> 
                                                        <span class='hljs-string'>"Content-Type: application/json" </span>
                                                        <span class='hljs-parameter'>-H </span> 
                                                        <span class='hljs-string'>"api-key: firewall" </span>
                                                        <span class='hljs-parameter'>-X </span> 
                                                        <span class='hljs-pscommand'>GET </span>
                                                        <span class='hljs-string'>"https://api.mopiclabs.com/product/"</span>
                                                    </span>
                                                </code>
                                            </pre>
                                        </div>
                                    </li>
                                    <li><p>After the first successful authentication, it can be used normally.</p></li>
                                </ol>




                                <p className="ft_b mb20">If the temperature in the environment where the MOPIC Display is used is too high or too low, the 3D screen may suddenly appear doubled.</p>
                                <p className="mb50">In this case, follow the steps below:</p>
                                <ol className='decimal'>
                                    <li><p>Press the [Test] button on the MOPIC Mirror while the MOPIC Display is connected.</p>
                                    <div class='img_wrap'><img src='/resources/images/tech/img_faq3.png'/></div>
                                    </li>
                                    <li><p>On the screen where six stones are visible, right-click twice.</p></li>
                                    <li><p>Move the 3D setting bar left and right with the mouse to find the position where the 3D image looks the clearest.</p></li>
                                    <li><p>Press [Save] to fix the changed setting value. Press [Reset] to return to the initial setting value.</p>
                                    <div class='img_wrap'><img src='/resources/images/tech/img_faq4.png'/></div>
                                    </li>
                                </ol>




                                <p className='mb50'>Check the following and take appropriate actions:</p>
                                <div class='img_wrap'><img src='/resources/images/tech/img_faq2.png'/></div>
                                <ol className='decimal'>
                                    <li>
                                        <p>Run the <a href='https://github.com/IntelRealSense/librealsense/releases/download/v2.54.1/Intel.RealSense.Viewer.exe' target='_blank'>RealSense Viewer</a> while the MOPIC Mirror is operating.</p>
                                    </li>
                                    <li>
                                        <p>Uncheck [Autoexposure] in the Intel RealSense Viewer.</p>
                                    </li>
                                    <li>
                                        <p>Gradually increase the [Controls > Exposure] value to above 10,000.</p>
                                    </li>
                                    <li>
                                        <p>Adjust while checking if eye tracking operates normally by viewing the MOPIC Mirror screen. <br /> If the exposure value is too high, X-talk may appear in the 3D video.</p>
                                    </li>
                                    <li>
                                        <p>Note that after closing and restarting MOPIC Mirror, the Intel RealSense Exposure value will reset, so you need to repeat steps 1-4.</p>
                                        
                                    </li>
                                </ol> 
                            </div>
*/}

              <div className="relevant">
                {lang == "en" ? (
                  <p className="rel_title">
                    Check out the relevant workarounds
                  </p>
                ) : (
                  <p className="rel_title">관련 대처 방법을 확인해보세요</p>
                )}
                <ul className="rel_list">
                  {list.map((row, idx) => (
                    <li key={"rel" + idx}>
                      <Link to={`/${lang}/tech/view/${row.post_id}`}>
                        {row.post_title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              {lang == "en" ? (
                <div className="help">
                  <p>Need more help resolving this issue?</p>
                  <Link to={"/" + lang + "/tech/send"} className="btn_help">
                    Yes
                  </Link>
                </div>
              ) : (
                <div className="help">
                  <p>문제 해결에 도움이 필요하신가요?</p>
                  <Link to={"/" + lang + "/tech/send"} className="btn_help">
                    네
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer lang={lang} className="mt0"></Footer>
    </>
  );
}
