/* eslint-disable */
import * as React from "react";
import { useEffect } from "react";
import Footer from "../common/Footer";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import MainNewsSlide from "./MainNewsSlide";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import YouTube from "react-youtube";
import LottiePlayer from "../modules/LottiePlayer";

export default function MainMobile(prop) {
  let lang = prop.lang;
  /*
    let startTouchPos = 0;
    let frameRotate = 0;
    let tempFrameRotate =0;
    function setFrame(pos) {

        tempFrameRotate = (frameRotate + pos/window.innerWidth*100);
        tempFrameRotate = (tempFrameRotate>28) ? 28 : tempFrameRotate;
        tempFrameRotate = (tempFrameRotate<-28) ? -28 : tempFrameRotate;
        $('.js-frame .frame_inner').css('transform','perspective(900px) rotateX(-4deg) rotateY('+tempFrameRotate+'deg)')
        let _frm = 50-Math.floor((tempFrameRotate + 28)/ (28*2)*50);
        rotateMonitor(_frm)
    }
    function rotateMonitor(num) {
        let canvas = document.getElementById('frame_canvas');
        let context = canvas.getContext('2d');
        num = num==0? 1 : num;
        num = 51- num;
        let img = imgAry[num];
        console.log(num);
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(img, 5,5, 800, 450);
        context.drawImage(frm, 0,0, 810, 460);
    }

    
    
    
    var imgAry = [];
    let frm = new Image();
    frm.src = "/resources/images/main/360/depth_frm.png";
    function toDigit(num) {
        return (num<10) ? "0"+num: num;
    }

    let monitor = (Math.floor(Math.random()*3)+2)
    function setMonitor() {
        let loaded = 0;
        for (let i=0; i<51; i++) {
            let img = new Image();
            img.src = "/resources/images/main/360/depth0" + monitor + "/depth"+toDigit(i)+".jpg";
            //img.src = "/resources/images/main/360/depth"+toDigit(i+1)+"-min.jpg";
            imgAry.push(img);
            img.onload = function() {
                loaded ++;
                if (loaded == 50) {
                    rotateMonitor(25);
                }
            }
        }
    }
*/

  function addZero(num) {
    let str = num.toString();
    while (str.length < 4) {
      str = "0" + str;
    }
    return str;
  }
  function loadSequence() {
    let seq = 0;
    let seq_total = 190;
    let seq_path = "/resources/images/main/cat2/cat";
    let seq_ext = ".png";
    let seq_fps = 1000 / 30;
    let seq_loop = false;
    let seq_loaded = 0;
    let seq_ary = [];
    for (let i = 1; i <= seq_total; i++) {
      let seq_img = new Image();
      seq_img.src = seq_path + addZero(i) + seq_ext;
      seq_ary.push(seq_img);
      seq_img.onload = function () {
        seq_loaded++;
        if (seq_loaded === seq_total) {
          onImageLoadComplete();
        }
      };
    }

    function onImageLoadComplete() {
      console.log("onImageLoadComplete");
      playSequence();
    }
    function playSequence() {
      let seq_cat = document.getElementById("seq_cat");
      let seq_interval = setInterval(function () {
        seq_cat.innerHTML = "";
        seq_cat.appendChild(seq_ary[seq]);
        seq++;
        if (seq === seq_total) {
          clearInterval(seq_interval);
        }
      }, seq_fps);
    }
  }

  const [swiperInstance, setSwiperInstance] = React.useState(null);

  function select_bs_acd(num) {
    for (
      let i = 0;
      i < $(".bs_acd .bs_acd_item .item_head button").length;
      i++
    ) {
      if (num === i) {
      } else {
        $(".bs_acd .bs_acd_item").eq(i).removeClass("on");
        $(".bs_acd .bs_acd_item").eq(i).find(".item_body").stop().slideUp(300);
      }
    }
    $(".bs_acd .bs_acd_item").eq(num).addClass("on");
    $(".bs_acd .bs_acd_item").eq(num).find(".item_body").stop().slideDown(300);
    //bs_swiper.slideTo(num);
    //console.log(num, swiperInstance);
    window.swiper = swiperInstance;
    swiperInstance.slideTo(num);
  }

  $(".bs_acd .bs_acd_item .item_head button")
    .off("click")
    .on("click", function () {
      let idx = $(".bs_acd .bs_acd_item .item_head button").index($(this));
      select_bs_acd(idx);
    });

  const opts = {
    height: window.innerHeight,
    width: (640 / 360) * window.innerHeight,
    rel: 0,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      volume: 0,
      loop: true,
      rel: 0,
      showinfo: 0,
      modestbranding: 0,
      playsinline: 1,
      controls: 0,
      mute: true,
      volume: 0,
    },
  };

  function _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.setVolume(0);
    event.target.playVideo();
  }
  function _onStateChange(event) {
    // access to player in all event handlers via event.target
    //event.target.pauseVideo();
    if (event.data === 1) {
      $(".video_wrap").removeClass("clickable");
    }
    if (event.data === 0) {
      //event.target.pauseVideo();
      event.target.playVideo();
      //window.scroll
      //$('html, body').animate({scrollTop: window.innerHeight}, 400);
    }
  }

  useEffect(() => {
    //loadSequence();
    //setMonitor()
  });
  return (
    <>
      <div id="container" className={lang}>
        <div id="contents" className="mb_main">
          <div className="section" id="section_holo">
            <div className="section_outer">
              <div className="section_inner holo_wrap0">
                <div className="section_wrap">
                  {lang === "kr" ? (
                    <p className="holo_title">
                      스마트폰에 부착하는 혁신적인 3D 렌즈 <br />
                      <span className="ef_glow">HoloGlass</span>를 지금
                      주문하세요!
                    </p>
                  ) : (
                    <>
                      <p className="holo_title">
                        <span className="ef_glow">HoloGlass</span> : the
                        Innovative 3D Lens <br />
                        for Smartphones - Order Now!
                      </p>
                    </>
                  )}
                  {/* <div className="img_wrap" id="seq_cat"></div> */}
                  <div className="img_wrap">
                    <video playsInline autoPlay muted id="c_vid">
                      <source
                        src="/resources/images/video/cat3.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
            <div className="section_inner holo_wrap1">
              <div className="section_wrap">
                {lang === "kr" ? (
                  <div className="holo_txt">
                    <p>
                      <span className="txt_white">3D 안경 없이도</span>{" "}
                      스마트폰을 통해 실감나는 <br />
                      3D 콘텐츠를 즐길 수 있습니다.
                    </p>
                    <p>
                      3D 콘텐츠를 시청하지 않을 때에도 <br />
                      <span className="txt_white">
                        작은 글씨까지 선명하게
                      </span>{" "}
                      보이도록 정밀하게 설계되어 <br />
                      <span className="txt_white">
                        일반 보호유리로도 사용할 수 있습니다.
                      </span>
                    </p>
                    <p>
                      HoloGlass는 <br />
                      전면 카메라를 사용한 아이트래킹, 스마트폰에 설치된 3D
                      소프트웨어, <br />
                      정밀하게 설계된 3D 렌즈를 통해
                      <span className="txt_white">
                        {" "}
                        고품질 3D 시청 경험을 선사합니다.
                      </span>
                    </p>
                    <ul>
                      <li>
                        지원 기종 : iPhone 13~16 시리즈 (안드로이드 추가 예정)
                      </li>
                      <li>지원 기능: 3D 동영상, 사진 촬영, 3D 시청 등</li>
                    </ul>
                    <p className="desc">
                      <span className="txt_white">
                      HoloGlass는 B2B 주문 제작으로만 판매됩니다. 개인 고객을 위한 낱개 판매는 지원되지 않습니다.
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className="holo_txt">
                    <p>
                      You can enjoy immersive 3D content through your smartphone{" "}
                      <span className="txt_white">without 3D glasses.</span>
                    </p>
                    <p>
                      It is precisely designed to display even small text
                      clearly, so it can also be used as a{" "}
                      <span className="txt_white">
                        regular screen protector when not viewing 3D content.
                      </span>
                    </p>
                    <p>
                      HoloGlass offers a{" "}
                      <span className="txt_white">
                        premium 3D viewing experience
                      </span>{" "}
                      through eye tracking using the front camera, 3D software
                      installed on the smartphone, and precision-engineered 3D
                      lenses.
                    </p>
                    <ul>
                      <li>
                        Supported devices : iPhone 13-16 (Android support comming soon) 
                      </li>
                      <li>
                        Supported features: 3D video/photo capture, 3D viewing,
                        etc.
                      </li>
                    </ul>
                    <p className="desc">
                      <span className="txt_white">
                        HoloGlass is sold only through B2B orders and is not
                        available for individual retail customers.
                      </span>
                    </p>
                  </div>
                )}
                <div className="img_wrap">
                  <img src="/resources/images/main/holo_img0.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          
        <div className="section" id="section1">
          <div className="section_inner">
            <div className="section_wrap">
              <div className="s_title">
                <p className="">
                  {lang === "kr"
                      ? "OpenXR로 더욱 다양한 3D 콘텐츠를 경험하세요!"
                      : "OpenXR Support Now Available!"}
                </p>
              </div>
              <div className="">
                <div className="switch_img">
                  <img src="/resources/images/main/main_switchable.png" alt="" className="switch_img0" />
                  <img src="/resources/images/product/switchable_prd_001.png" alt="" className="switch_img1" />
                </div>
              </div>
              <div className="s_desc" data-delaystart="0.6">
                <p className="desc_txt">
                  <span className="txt">
                    {lang === "kr"
                      ? "MOPIC의 3D 디스플레이가 OpenXR을 지원합니다."
                      : "MOPIC's 3D displays now support the OpenXR."}
                    
                  </span>
                </p>
                <p className="desc_txt">
                  <span className="txt">
                    {lang === "kr"
                      ? "이제 언리얼 엔진 기반을 포함한 다양한 3D 콘텐츠를 더욱 몰입감 있게 경험해보세요."
                      : "Experience immersive 3D with a wide range of content, including Unreal Engine-based applications."}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

          <div className="section active" id="section0">
            <div className="video_wrap clickable">
              {/* <img src="/resources/images/main/mb_visual.jpg" alt="" /> */}

              <YouTube
                videoId="huBILuEM_AE"
                opts={opts}
                onReady={_onReady}
                onStateChange={_onStateChange}
              />

              {/* <video playsInline autoPlay muted loop id="bgvid">
                        <source src="/resources/images/video/main_movie.mp4" type="video/mp4" />
                    </video> */}
            </div>
            <div className="section_inner">
              <div className="section_wrap">
                <div className="s_title">
                  {lang === "kr" ? (
                    <p>
                      현실의 나와 화면 속 세상을 <br /> 사실적으로 연결합니다
                    </p>
                  ) : (
                    <p>
                      Realistically connecting you to the world, simply through
                      a display
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="section" id="section1">
            <div className="section_inner">
              <div className="section_wrap">
                {lang === "kr" ? (
                  <div className="s_title">
                    <p>
                      디스플레이에 깊이감과 시차를 추가하는 라이트필드 3D는
                      창문을 통해 보는 듯한 시각적 경험을 제공합니다
                    </p>
                  </div>
                ) : (
                  <div className="s_title">
                    <p>
                      MOPIC's Light Field 3D Display: <br />
                      Adding Realistic Depth and Volume to 2D Screens
                    </p>
                  </div>
                )}

                <div className="video_box">
                  <video playsInline autoPlay muted loop id="a_vid">
                    <source
                      src="/resources/images/video/mopic_product.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>

                {/* <div className="frame_wrap js-frame" onTouchStart={(e)=> {
                            startTouchPos = e.changedTouches[0].pageX;
                        }}  onTouchMove={(e)=> {
                            let movePos = e.changedTouches[0].pageX - startTouchPos;
                            
                            setFrame(movePos)
                        }}  onTouchEnd={(e)=> {
                            frameRotate = tempFrameRotate;
                            
                        }} >
                            <div className="frame_inner">
                                <canvas id="frame_canvas" width="810" height="460"></canvas>
                            </div> 
                            <div className="lottiePlayer">
                                <LottiePlayer url={"hand_motion.json"} />
                            </div>
                        </div> */}

                {lang === "kr" ? (
                  <div className="s_desc">
                    <p className="desc_txt">
                      <span className="txt">
                        모픽의 라이트필드 3D 기술은 디스플레이에서 나오는 모든
                        빛을 원하는 방향으로 보낼 수 있어 실제 두 눈으로 보고
                        느끼는 3차원 공간감을 3D 안경 착용 없이도 평면
                        디스플레이에서 느낄 수 있게 합니다. 모픽의 라이트필드
                        3D는 영상 정보만으로 중요한 판단이나 정교한 조작을 할 수
                        있게 하고, 여러분의 3D 콘텐츠에 압도적 몰입감을
                        더합니다.
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className="s_desc">
                    <p className="desc_txt">
                      <span className="txt">
                        MOPIC's Light Field 3D display technologies are applied
                        to 2D displays with certain characteristics optimal for
                        3D performance. Customers can now feel a sense of space
                        and depth perception on flat-screen displays without the
                        need for 3D glasses. The Light Field 3D Display also
                        allows business users to make important judgment calls
                        and have more precise control when controlling objects
                        remotely using our products. The ability to give them 3D
                        depth perception allows them to see things as if they
                        were actually at the work site.
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <div className="section" id="section1-1">
                <div className="section_inner">
                    <div className="section_wrap">

                        <div className="s_title" >
                            <p className="">
                                {lang === "kr" ?
                                "CES 2024에서 모픽 라이트필드 디스플레이를 만나보세요!"
                                : "Meet MOPIC's Light Field 3D display at the CES 2024!"}
                            </p>
                        </div>
                        <div className="img_wrap">
                            <div className="">
                                <img src="/resources/images/main/ces2024.jpg" alt="CES 2024" />
                            </div> 
                        </div>
                        <div className="s_desc" data-delaystart="0.6">
                            <p className="desc_txt"><span className="txt">
                                {lang === "kr" ?
                                "고객들은 이미 눈에 띄는 전시를 위해 모픽의 라이트필드 디스플레이 솔루션을 적극적으로 활용하고 있습니다."
                                : "Customers are already utilizing MOPIC's light field display solution for eye-catching exhibitions."}​
                            </span></p>
                            <p className="desc_txt"><span className="txt">
                                {lang === "kr" ?
                                "Orbbec과 Lotte Data Communication 부스에서는 모픽의 라이트필드 3D 디스플레이를 직접 만나보실 수 있습니다. "
                                : "Visit MOPIC's light field 3D display at Orbbec's booth (#9745 North Hall) and Lotte Data Communication's booth (#18242 Central Hall)."}
                            </span></p>
                            <p className="desc_txt"><span className="txt">
                                {lang === "kr" ?
                                "(Orbbec - #9745 North Hall & Lotte Data Communication - #18242 Central Hall)"
                                : null}
                            </span></p>
                        </div>
                    </div>
                </div>
            </div> */}
          <div className="section" id="section2">
            <div className="section_inner">
              <div className="section_wrap">
                {lang === "kr" ? (
                  <div className="s_title ">
                    <p className="tit">
                      고객의 요구에 최적화된 라이트필드 3D 솔루션을 제공합니다
                    </p>
                  </div>
                ) : (
                  <div className="s_title ">
                    <p className="tit">
                      We provide 3D display solutions optimized for the
                      customer's needs
                    </p>
                  </div>
                )}

                {lang === "kr" ? (
                  <div className="s_desc">
                    <p className="desc_txt">
                      <span className="txt">
                        두 눈으로 보는 시야 그대로를 3D 카메라로 촬영하는 기술,
                        3D 그래픽 콘텐츠를 최적의 입체영상으로 추출하는 기술,
                        어떤 위치에서도 일관적인 입체감을 제공하는 소프트웨어
                        알고리즘, 그리고 고해상도로 잔상 없이 선명한 입체영상을
                        보여주는 광학 기술을 조합해 제공하는 On-demand 솔루션은
                        놀라운 결과를 만들어 냅니다.
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className="s_desc">
                    <p className="desc_txt">
                      <span className="txt">
                        The on-demand solution produces marvelous results using
                        a combination of technologies: shooting with a 3D camera
                        as seen with the naked eye, extracting 3D graphic
                        content as optimal stereoscopic images, offering a
                        software algorithm that enables consistent
                        three-dimensional effects, and implementing an optical
                        technology that offers stereoscopic images in high
                        resolution without any Cross talk(X-talk) interference.
                      </span>
                    </p>
                  </div>
                )}

                <div className="main_mb_solutions">
                  <Swiper
                    modules={[Navigation]}
                    spaceBetween={22}
                    speed={800}
                    slidesPerView={1}>
                    <SwiperSlide>
                      <div className="slt_cont_img">
                        <img src="/resources/images/main/slt_img0.jpg" alt="" />
                      </div>
                      {lang === "kr" ? (
                        <div className="slt_cont_txt">
                          <p className="tit">
                            내시경 수술 장비로 녹화된 3D 영상​
                          </p>
                          <p className="txt">
                            모픽 솔루션은 실제 3D 수술 장비 화면과 차이가 없을
                            정도로 입체감 구현성은 만점을 주고 싶습니다.
                            레지던트, 인턴 대상으로 교육하기에는 최적의
                            시스템이라고 생각이 듭니다! <br />
                            <br />
                            -서울대학교 흉부외과 성용원 교수-
                          </p>
                        </div>
                      ) : (
                        <div className="slt_cont_txt">
                          <p className="tit">
                            3D images recorded with endoscopic surgical
                            equipment
                          </p>
                          <p className="txt">
                            I'd like to give full credit to MOPIC's solution for
                            an excellent three-dimensional effect, which is
                            hardly distinguishable from the real 3D robotic
                            surgery images. I believe it is a system optimized
                            for residents and interns in training. <br />
                            <br />
                            -Prof. Seong Yong-won, Department of Thoracic and
                            Cardiovasular Surgery, Seoul National University
                            Hospital.-
                          </p>
                        </div>
                      )}
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="slt_cont_img">
                        <img src="/resources/images/main/slt_img1.jpg" alt="" />
                      </div>
                      {lang === "kr" ? (
                        <div className="slt_cont_txt">
                          <p className="tit">
                            전자현미경을 통해 보이는 3D 영상
                          </p>
                          <p className="txt">
                            2D 화면을 통한 현미경 관찰 솔루션을 흔하게 사용해
                            왔지만, 정확한 깊이 인식이 필요한 실험에서는 반드시
                            직접 접안경에 눈을 대고 목을 구부려 관찰해야만
                            했습니다. 이 과정이 3D 디스플레이 시스템 하나로
                            해결이 되니 매우 편리합니다. 교육 현장에 확대 적용
                            된다면, 교수자의 현미경 한 대로 여러 학생들이 동시에
                            같은 관찰 경험을 할 수 있다는 점도 매력적이네요!{" "}
                            <br />
                            <br />
                            -경희대학교 정보디스플레이학과 김석현 교수-
                          </p>
                        </div>
                      ) : (
                        <div className="slt_cont_txt">
                          <p className="tit">
                            3D images captured through the stereo digital
                            microscope
                          </p>
                          <p className="txt">
                            I've been mostly using a 2D digital solution for
                            microscopic observation, and I had to lean into the
                            microscope eyepiece when performing experiments that
                            demanded an accurate sense of depth. Now, just a 3D
                            display system does the trick. It is also appealing
                            that, if widely adopted in lectures, a single
                            microscope at the professor's desk will promise the
                            entire class interactive experiment. <br />
                            <br />
                            -Prof. Kim Suk-hyun, Department of Information
                            Display, Kyung Hee University.-
                          </p>
                        </div>
                      )}
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="slt_cont_img">
                        <img src="/resources/images/main/slt_img2.jpg" alt="" />
                      </div>
                      {lang === "kr" ? (
                        <div className="slt_cont_txt">
                          <p className="tit">3D 그래픽</p>
                          <p className="txt">
                            일반 화면과 똑같이 생긴 디지털 사이니지에서 입체감이
                            느껴져 놀랐습니다. 화면 밖으로 튀어나오는 아기자기한
                            형상들은 관람객들에게 신선한 경험과 함께 건축물의
                            아름다움을 더 오래 기억하도록 만들어 줄 것 같습니다!{" "}
                            <br />
                            <br />- 카사바트요 마케팅팀 관계자 -
                          </p>
                        </div>
                      ) : (
                        <div className="slt_cont_txt">
                          <p className="tit">3D graphics</p>
                          <p className="txt">
                            It blows my mind that Digital Signage, which looks
                            exactly like ordinary displays, provides a
                            three-dimensional effect. I think the floating 3D
                            shapes will deliver viewers refreshing experience
                            and help create lasting memories of architectural
                            beauty.
                            <br />
                            <br />- Sandra, Marketing Team of Casa Batlló. -
                          </p>
                        </div>
                      )}
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="slt_cont_img">
                        <img src="/resources/images/main/slt_img3.jpg" alt="" />
                      </div>
                      {lang === "kr" ? (
                        <div className="slt_cont_txt">
                          <p className="tit">
                            상용 카메라 두 대를 통해 보이는 3D 영상
                          </p>
                          <p className="txt">
                            가까운 물체와 먼 물체가 화면상에서도​ 떨어져 있는
                            것처럼 보였고​, 어느 정도 떨어져 있는지도 가늠이
                            되었습니다​. 마치 실제 조종석에서 보는 것 같은
                            기분이 들었습니다​! <br />
                            <br />- H중공업 관계자-
                          </p>
                        </div>
                      ) : (
                        <div className="slt_cont_txt">
                          <p className="tit">
                            3D images captured through two commercial cameras
                          </p>
                          <p className="txt">
                            Close objects and far objects look really like they
                            are apart from one another on the screen. I can
                            figure out how far they are away from one another.
                            It makes me feel like I am in a real cockpit. <br />
                            <br />- An official of H Heavy Industry-
                          </p>
                        </div>
                      )}
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="slt_cont_img">
                        <img src="/resources/images/main/slt_img4.jpg" alt="" />
                      </div>
                      {lang === "kr" ? (
                        <div className="slt_cont_txt">
                          <p className="tit">3D 그래픽</p>
                          <p className="txt">
                            게임 화면을 보면서 즐기는 실내 사이클을 이렇게
                            ​입체영상으로 보면서 탄다면 더 사실과 같은 기분으로
                            오래 탈 수 있겠죠? <br />
                            저는 특히 실내 사이클 초보자 분들에게 추천합니다!{" "}
                            <br />
                            <br />- 실내 사이클 체험장 K-Velo 이미란 대표-
                          </p>
                        </div>
                      ) : (
                        <div className="slt_cont_txt">
                          <p className="tit">3D graphics</p>
                          <p className="txt">
                            The 3D images will offer a realistic riding
                            experience, helping people who try a stationary bike
                            first time to work out longer <br />
                            <br />- Lee Mi-ran, Head of K-Velo, Stationary Bike
                            Experience Space. -
                          </p>
                        </div>
                      )}
                    </SwiperSlide>
                  </Swiper>
                  {/* <Swiper
                            spaceBetween={16}
                            slidesPerView={"auto"}
                            >
                            <SwiperSlide className="mb_sol_item">
                                <div className="img_wrap">
                                    <div className="img_inner">
                                        <img src="/resources/images/main/main_solution0.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="cont_wrap">
                                    {lang === "kr" ?
                                    <div className="cont_inner">
                                        <p className="text name">3D 그래픽</p>
                                        <p className="text description">3차원 그래픽을 3차원 공간 그대로 시청할 수 있습니다.</p>
                                    </div>
                                    :
                                    <div className="cont_inner">
                                        <p className="text name">3D Graphic</p>
                                        <p className="text description">This technology enables watching 3D graphics as if in three-dimensional spaces.</p>
                                    </div>
                                    }
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="mb_sol_item">
                                <div className="img_wrap">
                                    <div className="img_inner">
                                        <img src="/resources/images/main/main_solution1.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="cont_wrap">
                                    {lang === "kr" ?
                                    <div className="cont_inner">
                                        <p className="text name">3D 촬영 영상</p>
                                        <p className="text description">떨어진 다른 공간을 사실적으로 연결할 수 있습니다.  </p>
                                    </div>
                                    :
                                    <div className="cont_inner">
                                        <p className="text name">3D Capture</p>
                                        <p className="text description">This technology enables connecting distant spaces realistically.  </p>
                                    </div>
                                    }
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="mb_sol_item">
                                <div className="img_wrap">
                                    <div className="img_inner">
                                        <img src="/resources/images/main/main_solution2.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="cont_wrap">
                                    {lang === "kr" ?
                                    <div className="cont_inner">
                                        <p className="text name">사이니지</p>
                                        <p className="text description">다수의 시선을 사로잡는 몰입형 광고를 할 수 있습니다. </p>
                                    </div>
                                    :
                                    <div className="cont_inner">
                                        <p className="text name">Advertisement Signage</p>
                                        <p className="text description">This technology enables an increased sense of immersion in advertisements, catching many people's attention.</p>
                                    </div>
                                    }
                                </div>
                            </SwiperSlide>
                        </Swiper> */}
                </div>

                <div className="btn_wrap tac">
                  <Link
                    to={"/" + lang + "/solution"}
                    className="btn btn_sm btn_outline btn_blue btn_more">
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="section" id="section3">
            <div className="section_inner">
              <div className="section_wrap">
                <div className="s_title">
                  {lang === "kr" ? (
                    <p>화면에 깊이감이 필요하다면 모픽과 상의하세요! </p>
                  ) : (
                    <>
                      <p>
                        Consult with MOPIC if you need to add 3D depth
                        perception to any display screen{" "}
                      </p>
                    </>
                  )}
                </div>
                {lang === "kr" ? (
                  <div className="s_desc">
                    <p className="desc_txt">
                      <span className="txt">
                        모픽은 파트너쉽을 기반으로 시너지효과가 가장 큰 비즈니스
                        모델을 구축하고 있습니다. 모픽의 라이트필드 3D 솔루션은
                        고객사의 비즈니스에 필요한 상태로 제공됩니다.{" "}
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className="s_desc">
                    <p className="desc_txt">
                      <span className="txt">
                        We bring you the best synergies through partnerships.
                        MOPIC's Light Field 3D solution is tailored for your
                        business.
                      </span>
                    </p>
                  </div>
                )}
                <div className="business_wrap">
                  <div className="business_inner">
                    <ul className="bs_box">
                      <li className="bs_inner0">
                        <div className="circle_wrap">
                          <p className="cc_tit">ODM</p>
                        </div>
                        <p className="tit">
                          라이트필드 3D 커스텀 제작이 필요하세요?
                        </p>
                        <p className="txt">
                          고객사의 시스템에 완벽히 결합하는 라이트필드 3D
                          소프트웨어, 그리고 고객사의 요청에 맞는 입체영상
                          디바이스 제작까지 On-demand 토탈 솔루션을 제공합니다.{" "}
                        </p>
                      </li>
                      <li className="bs_inner1">
                        <div className="circle_wrap">
                          <p className="cc_tit">License</p>
                        </div>
                        <p className="tit">
                          직접 라이트필드 3D 디바이스를 제작하고 싶나요?{" "}
                        </p>
                        <p className="txt">
                          고객사의 시스템에 완벽히 결합하는 라이트필드 3D
                          소프트웨어와 라이트필드 3D를 제작할 수 있는 광학 설계
                          노하우를 제공합니다.
                        </p>
                      </li>
                      <li className="bs_inner2">
                        <div className="circle_wrap">
                          <p className="cc_tit">API</p>
                        </div>
                        <p className="tit">3D 콘텐츠를 가지고 있다면?</p>
                        <p className="txt">
                          3D 콘텐츠에서 최적의 입체감을 추출해 라이트필드 3D
                          형식으로 변환하는 API를 제공합니다. ​콘텐츠
                          제작사에게는 파트너십을 통해 무료 라이선스를
                          제공합니다.
                        </p>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="business_inner">
                                <div className="bs_slide_wrap">
                                    <Swiper
                                        spaceBetween={16}
                                        slidesPerView={1}
                                        onSwiper={setSwiperInstance}
                                        allowTouchMove = {false}
                                        >
                                        <SwiperSlide className="">
                                            <Link to={"/"+lang+"/business"}>
                                                <div className="img_wrap">
                                                    <img src="/resources/images/main/main_bs_img0.jpg" alt="" />
                                                </div>
                                                {lang === "kr" ?
                                                <p className="txt">고객사의 디지털 현미경 시스템에 연결 가능한 모픽 라이트필드 3D 디바이스와 소프트웨어를 공급​ </p>
                                                :
                                                <p className="txt">Offer MOPIC’s software and devices that can be connected with customer's digital microscope system​​ </p>
                                                }
                                            </Link>
                                        </SwiperSlide>
                                        <SwiperSlide className="">
                                            <Link to={"/"+lang+"/business"}>
                                                <div className="img_wrap">
                                                    <img src="/resources/images/main/main_bs_img1.jpg" alt="" />
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                        <SwiperSlide className="">
                                            <Link to={"/"+lang+"/business"}>
                                                <div className="img_wrap">
                                                    <img src="/resources/images/main/main_bs_img2.jpg" alt="" />
                                                </div>
                                                {lang === "kr" ?
                                                <p className="txt">3D 콘텐츠 제작사가 보유한 3D 콘텐츠를​ 모픽 소프트웨어와 디바이스를 통해 바로 확인하는 모습​</p>
                                                :
                                                <p className="txt">The scene where 3D content producers instantly check their 3D content through MOPIC’s software and devices​​</p>
                                                }
                                            </Link>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>

                                <div className="bs_acd">
                                    <div className="bs_acd_item on">
                                        <div className="item_head">
                                            <button>
                                                {lang === "kr" ?
                                                <span className="tit">라이트필드 3D 커스텀 제작이 필요하세요?</span>
                                                :
                                                <span className="tit">Do you need a customized Light Field 3D solution?</span>
                                                }
                                            </button>
                                        </div>
                                        <div className="item_body" style={{"display":"block"}}>
                                            {lang === "kr" ?
                                            <span>
                                                고객사의 시스템에 완벽히 결합하는 라이트필드 3D 소프트웨어, 
                                                그리고 고객사의 요청에 맞는 입체영상 디바이스 제작까지 
                                                On-demand 토탈 솔루션을 제공합니다. 
                                            </span>
                                            :
                                            <span>
                                                MOPIC provides total solutions on-demand ranging from Light Field 3D software that perfectly fits the customer's system to the production of custom-made stereoscopic image devices.
                                            </span>
                                            }
                                        </div>
                                    </div>
                                    <div className="bs_acd_item">
                                        <div className="item_head">
                                            <button>
                                                {lang === "kr" ?
                                                <span className="tit">직접 라이트필드 3D 디바이스를 제작하고 싶나요?</span>
                                                :
                                                <span className="tit">Do you want to produce your own Light Field 3D devices?</span>
                                                }
                                            </button>
                                        </div>
                                        <div className="item_body">
                                            {lang === "kr" ?
                                            <span>고객사의 시스템에 완벽히 결합하는 라이트필드 3D 소프트웨어와 라이트필드 3D를 제작할 수 있는 광학 설계 노하우를 제공합니다.</span>
                                            :
                                            <span>We provide Light Field 3D software that can be perfectly coupled with your system, hardware design specifications, and technical know-how to give you the ability to produce your own stereoscopic image devices.</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="bs_acd_item">
                                        <div className="item_head">
                                            <button>
                                                {lang === "kr" ?
                                                <span className="tit">3D 콘텐츠를 가지고 있다면?</span>
                                                :
                                                <span className="tit">Do you have your own 3D content? Present them with impactful engagement!</span>
                                                }
                                            </button>
                                        </div>
                                        <div className="item_body">
                                            {lang === "kr" ?
                                            <span>3D 콘텐츠에서 최적의 입체감을 추출해 라이트필드 3D 형식으로 변환하는 API를 제공합니다. ​콘텐츠 제작사에게는 파트너십을 통해 무료 라이선스를 제공합니다.</span>
                                            :
                                            <span>MOPIC provides an API that extracts optimal three-dimensional effects from 3D content and then converts them into Light Field 3D formats. MOPIC offers complimentary licenses to content producers through partnerships.</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="section" id="section7">
            <div className="section_inner ">
              <div className="sect_bg"></div>
              <div className="section_wrap">
                <div className="s_title">
                  {lang === "kr" ? (
                    <p className="" data-delaystart="0.5">
                      준비된 제품을 먼저 사용해 보세요
                    </p>
                  ) : (
                    <>
                      <p className="" data-delaystart="0.5">
                        Try Using the Prepared Products{" "}
                      </p>
                    </>
                  )}
                </div>
                {lang === "kr" ? (
                  <div className="s_desc" data-delaystart="1.1">
                    <p className="desc_txt">
                      <span className="txt">
                        모픽은 다양한 디스플레이 사이즈, 3D 기술 구현 방식, 사용
                        환경 등을 반영한 여러가지의 하드웨어 샘플을 제공할 수
                        있습니다.
                      </span>
                    </p>
                    <p className="desc_txt">
                      <span className="txt">
                        고객사는 모픽이 제공하는 샘플을 활용해 빠른 시간에
                        자신들의 시스템에 접목해 검토할 수 있습니다.{" "}
                      </span>
                    </p>
                    <p className="desc_txt">
                      <span className="txt">
                        기존 3D 콘텐츠와 호환되는 소프트웨어를 함께 제공합니다.{" "}
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className="s_desc" data-delaystart="1.1">
                    <p className="desc_txt">
                      <span className="txt">
                        MOPIC can provide a variety of hardware samples that
                        reflect various display sizes, 3D technology
                        implementation methods, and usage environments.​
                      </span>
                    </p>
                    <p className="desc_txt">
                      <span className="txt">
                        Customers can use the samples provided by MOPIC to
                        quickly integrate them into their own systems and
                        review.
                      </span>
                    </p>
                    <p className="desc_txt">
                      <span className="txt">
                        Also, software compatible with existing 3D contents will
                        be provided.
                      </span>
                    </p>
                  </div>
                )}
                <div className="b2b_wrap ">
                  <div className="b2b_inner">
                    <ul className="bs_box">
                      <li className="bs_inner0">
                        <img src="/resources/images/main/b2b_15.png" alt="" />
                      </li>
                      <li className="bs_inner1">
                        <img src="/resources/images/main/b2b_32.png" alt="" />
                      </li>
                      <li className="bs_inner2">
                        <img src="/resources/images/main/b2b_65.png" alt="" />
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="btn_wrap tac">
                  <Link
                    to={"/" + lang + "/product"}
                    className="btn btn_sm btn_outline btn_blue btn_more">
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="section" id="section4">
            <div className="section_inner">
              <div className="section_wrap">
                {lang === "kr" ? (
                  <div className="s_title">
                    <p>최고의 글로벌 기업들이 모픽과 함께하고 있습니다</p>
                  </div>
                ) : (
                  <div className="s_title">
                    <p>Major global tech firms work with MOPIC</p>
                  </div>
                )}
                <div className="s_desc">
                  <Swiper spaceBetween={16} slidesPerView={"auto"}>
                    <SwiperSlide>
                      <img src="/resources/images/main/sponsor0.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/resources/images/main/sponsor1.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/resources/images/main/sponsor2.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/resources/images/main/sponsor9.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/resources/images/main/sponsor13.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/resources/images/main/sponsor11.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/resources/images/main/sponsor5.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/resources/images/main/sponsor3.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/resources/images/main/sponsor12.png" alt="" />
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className="btn_wrap tac">
                  <Link
                    to={"/" + lang + "/casestudy/list"}
                    className="btn btn_sm btn_outline btn_blue btn_more">
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="section news_section" id="section5">
            <div className="section_inner">
              <div className="section_wrap">
                <div className="news_header">
                  {lang === "kr" ? (
                    <div className="s_title" data-delaystart="0.5">
                      뉴스
                    </div>
                  ) : (
                    <div className="s_title" data-delaystart="0.5">
                      NEWS
                    </div>
                  )}
                </div>
                <div className="news_body">
                  <MainNewsSlide type={"news"} lang={lang}></MainNewsSlide>
                </div>
                <div className="btn_wrap">
                  <Link
                    to={"/" + lang + "/news/list"}
                    className="btn btn_sm btn_outline btn_blue btn_more">
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="section" id="section6">
            <div className="sect_bg"></div>

            <div className="section_inner">
              <div className="section_wrap">
                <div className="s_title">
                  {lang === "kr" ? (
                    <p>지금 라이트필드 3D 전문가와 상의하세요!</p>
                  ) : (
                    <p>Consult with Light Field 3D experts now!</p>
                  )}
                </div>

                <div className="btn_wrap tac">
                  {lang === "kr" ? (
                    <Link
                      to={"/" + lang + "/contact"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn_sm btn_outline btn_white_line btn_more">
                      문의하기
                    </Link>
                  ) : (
                    <Link
                      to={"/" + lang + "/contact"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn_sm btn_outline btn_white_line btn_more">
                      Contact Us
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <Footer lang={lang}></Footer>
          </div>
        </div>
      </div>
    </>
  );
}
